<template>
  <div class="detail-player" @click="$emit('close')">
    <iframe :src="src" class="detail-player-iframe" allowfullscreen></iframe>
  </div>
</template>

<script>
export default {
  name: 'DetailPlayer',
  props: {
    src: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="scss" scoped="scoped">
.detail-player {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  padding: 90px;
  background: #000000ab;
  animation: transition-in 600ms;
  animation-fill-mode: both;

  @media (max-width: 500px) {
    padding: 90px 40px;
  }

  &-iframe {
    height: 100%;
    width: 100%;
    border: none;
    background: black;
    animation: transition-iframe-in 600ms;
    animation-fill-mode: both;
  }
}

@keyframes transition-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes transition-iframe-in {
  from {
    transform: scaleX(0.8) scaleY(0.9);
    box-shadow: 0 0 0 black;
  }

  to {
    transform: scaleX(1) scaleY(1);
    box-shadow: 0 0 200px black;
  }
}
</style>
