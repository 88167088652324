<template>
  <a class="detail-action" @click="$emit('return')">
    <img src="@/assets/icons/close.svg" alt>
  </a>
</template>

<script>
export default {
  name: 'DetailAction'
}
</script>

<style lang="scss" scoped="scoped">
  .detail-action {
    cursor: pointer;
    background: black;
    color: white;
    display: flex;
    flex-direction: column;
    font-size: 24px;
    padding: 40px;
    position: absolute;
    top: 0;
    animation: transition-in 600ms;
    animation-delay: 1000ms;
    animation-fill-mode: both;

    img {
      width: 40px;
      transition: 0.6s;
    }

    &:hover {
      img {
        transform: rotate(180deg) scale(0.8);
      }
    }

    &:active {
      img {
        transform: rotate(360deg) scale(0.6);
      }
    }
  }

  @keyframes transition-in {
    from {
      opacity: 0;
      transform: translateX(-50%) translateY(-50%);
    }

    to {
      opacity: 1;
      transform: translate(0);
    }
  }
</style>
