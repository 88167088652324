<template>
  <div class="detail-meta">
    <div class="detail-meta-inner">
      {{ formattedDate }}
    </div>
    <div class="detail-meta-inner">
      <template v-if="slide.categories.length > 0">
          <span v-for="(category, index) in slide.categories" :key="category">
              <template v-if="index > 0">,</template>
              {{ category }}
            </span>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DetailMeta',
  props: {
    slide: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    formattedDate () {
      const date = []

      date.push(this.slide.date.getDate())
      date.push(this.slide.date.getMonth() + 1)
      date.push(this.slide.date.getFullYear())

      return date.map(item => item > 9 ? item : `0${item}`).join('.')
    }
  }
}
</script>

<style lang="scss" scoped="scoped">
  .detail-meta {
    display: flex;
    justify-content: space-between;
    font-style: italic;
    font-size: 20px;
    color: #1a1a1a;
    max-width: 700px;
    margin: 0 auto 40px;
  }
</style>
