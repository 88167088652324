<template>
  <div class="detail-content">
    <template v-for="content in contents" :key="content">
      <p v-if="content.type === TYPES.TEXT" class="detail-content-text">
        {{ content.text }}
      </p>
      <div v-else-if="content.type === TYPES.IMAGE" :class="['rellax', 'detail-content-image', `detail-content-image-${content.index}`]" :data-rellax-speed="-1">
        <img :src="content.path" :alt="content.title">
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: 'DetailContent',
  components: {
  },
  props: {
    slide: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      TYPES: {
        TEXT: 'text',
        IMAGE: 'image'
      },
      rellax: null
    }
  },
  mounted () {
    // TODO implement rellax
    /* this.rellax = new Rellax('.rellax', {
      wrapper: '.detail'
    }) */
  },
  computed: {
    contents () {
      const PARTS = 3

      const contents = []

      const texts = this.slide.text.split(' ')
      const textSplitter = texts.length / PARTS

      const images = this.slide.images
      let imageIndex = 1

      for (const [index, value] of new Array(PARTS).entries()) {
        let currentText = value

        if (index === 0) {
          // the first
          currentText = `${texts.splice(0, textSplitter).join(' ')} ...`
        } else if (index + 1 === PARTS) {
          // the last
          currentText = `... ${texts.join(' ')}`
        } else {
          // all in between
          currentText = `... ${texts.splice(0, textSplitter).join(' ')} ...`
        }

        contents.push({
          type: this.TYPES.TEXT,
          text: currentText
        })

        let currentImages

        if (index === 0 || index === 1) {
          currentImages = images.splice(0, 2)
        } else {
          currentImages = images
        }

        currentImages.forEach(image => {
          contents.push({
            type: this.TYPES.IMAGE,
            path: image.path,
            title: image.title,
            index: imageIndex
          })

          imageIndex++
        })
      }

      return contents
    }
  }
}
</script>

<style lang="scss" scoped="scoped">
.detail-content {
  max-width: 700px;
  margin: 0 auto;

  &-text {
    font-size: 20px;
    margin: 100px 0;

    &:first-child {
      margin-top: 60px;
    }

    @media (max-width: 500px) {
      margin: 30px 0;
    }
  }

  &-image {
    position: relative;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &-1 {
      z-index: 2;
      width: 270px;
      height: 300px;
      margin-left: -80px;
    }

    &-2 {
      z-index: 1;
      width: 700px;
      height: 400px;
      margin-left: auto;
      margin-right: -80px;
      margin-top: -180px;
      display: block;
    }

    &-3 {
      z-index: 2;
      width: 700px;
      height: 400px;
      margin-left: auto;
      margin-right: -80px;
    }

    &-4 {
      z-index: 1;
      width: 500px;
      height: 280px;
      margin-left: -80px;
      margin-top: -40px;
      display: block;
    }
  }
}
</style>
