<template>
  <div :class="['detail', classes]">
    <div class="detail-cover" :style="'background-image: url(' + slide.cover + ')'">
      <div class="detail-cover-overlay"></div>
      <div class="detail-cover-title-wrapper">
        <h1 class="detail-cover-title">{{ slide.title }}</h1>

        <div v-if="slide.video" class="detail-cover-player" @click="showPlayer = true">
          <div class="detail-cover-player-inner">
            <div class="detail-cover-player-arrow">
              <div class="detail-cover-player-arrow-inner"></div>
            </div>
          </div>
          <div class="detail-cover-player-text">
            play video
          </div>
        </div>
      </div>
    </div>
    <div class="detail-wrapper">
      <detail-meta :slide="slide" />
      <detail-content :slide="slide" />
    </div>

    <detail-action @return="transitionHome" />
  </div>

  <detail-player v-if="showPlayer" :src="slide.video" @close="showPlayer = false" />
</template>

<script>
import slides from '@/content/slides'
import DetailMeta from '@/components/detail/DetailMeta'
import DetailContent from '@/components/detail/DetailContent'
import DetailAction from '@/components/detail/DetailAction'
import DetailPlayer from '@/components/detail/DetailPlayer'

export default {
  name: 'Detail',
  components: {
    DetailPlayer,
    DetailContent,
    DetailMeta,
    DetailAction
  },
  data () {
    return {
      slides,
      classes: '',
      showPlayer: false
    }
  },
  computed: {
    slide () {
      const slide = this.slides.find(item => item.title.toLowerCase().replaceAll(' ', '-') === this.$route.params.id)

      if (slide) {
        return slide
      } else {
        this.returnHome()
      }

      return {}
    }
  },
  methods: {
    transitionHome () {
      this.classes = 'detail-leaving'

      setTimeout(() => {
        this.returnHome()
      }, 600)
    },
    returnHome () {
      this.$router.push({
        name: 'Home'
      })
    }
  },
  beforeRouteLeave (to, from, next) {
    this.classes = 'detail-leaving'

    setTimeout(() => {
      next()
    }, 600)
  }
}
</script>

<style lang="scss" scoped="scoped">
.detail {
  height: 100%;
  width: 100%;
  transition: 600ms;
  animation: transition-in 600ms;
  animation-delay: 150ms;
  animation-fill-mode: both;
  overflow: hidden auto;

  &-cover {
    height: calc(100% - 120px);
    width: calc(100% - 120px);
    position: relative;
    margin: 60px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
    animation: transition-cover 600ms;
    animation-delay: 150ms;
    animation-fill-mode: both;

    @media (max-width: 500px) {
      margin: 30px;
      height: calc(100% - 60px);
      width: calc(100% - 60px);
      animation: transition-cover-mobile 600ms;
    }

    &-overlay {
      height: 100%;
      width: 100%;
      position: absolute;
      background: linear-gradient(0deg, rgba(0,0,0,0.8) 0%, rgba(0,0,0,0.5) 40%, rgba(255,255,255,0) 100%);
    }

    &-title {
      text-align: center;
      color: #fff;
      margin: 0;
      font-size: 90px;
      animation: transition-text 600ms;
      animation-delay: 250ms;
      animation-fill-mode: both;

      &-wrapper {
        z-index: 1;
        overflow: hidden;
      }
    }

    &-player {
      position: absolute;
      right: 0;
      bottom: 0;
      height: 60px;
      margin: 50px;
      display: flex;
      cursor: pointer;
      background: white;
      transition: 0.6s;

      @media (max-width: 500px) {
        width: calc(100% - 100px);
        justify-content: center;
      }

      &-inner {
        z-index: 1;
        width: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: 0.6s;
      }

      &-arrow {
        height: 30px;
        width: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: 0.6s;

        &-inner {
          border-bottom: 10px solid transparent;
          border-left: 19px solid black;
          border-top: 10px solid transparent;
          height: 0;
          width: 0;
          margin-left: 4px;
        }
      }

      &-text {
        color: black;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: bold;
        text-transform: uppercase;
        padding: 0 30px 0 60px;
        margin-left: -60px;
        transition: 0.6s;
      }

      &:hover {
        .detail-cover-player {
          &-arrow {
            transform: rotate(360deg);
          }

          &-text {
            padding-left: 65px;
          }
        }
      }

      &:active {
        .detail-cover-player {
          &-arrow {
            transform: rotate(720deg) scale(0.6);
          }

          &-text {
            padding-left: 50px;
          }
        }
      }
    }
  }

  &-wrapper {
    margin: 30px 60px 60px;

    @media (max-width: 500px) {
      margin: 30px;
    }
  }

  &-leaving {
    animation: transition-out 600ms;
    animation-fill-mode: both;

    .detail-cover {
      animation: transition-cover-out 600ms;
      animation-fill-mode: both;

      @media (max-width: 500px) {
        animation: transition-cover-mobile-out 600ms;
      }
    }
  }
}

@keyframes transition-in {
  from {
    opacity: 0;
    transform: translateY(-50%) scale(0.9);
  }

  to {
    opacity: 1;
    transform: translateY(0) scale(1);
  }
}

@keyframes transition-out {
  from {
    opacity: 1;
    transform: translateY(0) scale(1);
  }

  to {
    opacity: 0;
    transform: translateY(30%) scale(0.9);
  }
}

@keyframes transition-cover {
  from {
    transform: translateY(100%);
    height: calc(100% - 0px);
    width: calc(100% - 0px);
    margin: 0;
  }

  to {
    transform: translateY(0);
    height: calc(100% - 120px);
    width: calc(100% - 120px);
    margin: 60px;
  }
}

@keyframes transition-cover-out {
  from {
    transform: translateY(0);
    height: calc(100% - 120px);
    width: calc(100% - 120px);
    margin: 60px;
  }

  to {
    transform: translateY(-20%);
    height: calc(100% - 0px);
    width: calc(100% - 0px);
    margin: 0;
  }
}

@keyframes transition-cover-mobile {
  from {
    transform: translateY(100%);
    height: calc(100% - 0px);
    width: calc(100% - 0px);
    margin: 0;
  }

  to {
    transform: translateY(0);
    height: calc(100% - 60px);
    width: calc(100% - 60px);
    margin: 30px;
  }
}

@keyframes transition-cover-mobile-out {
  from {
    transform: translateY(0);
    height: calc(100% - 60px);
    width: calc(100% - 60px);
    margin: 30px;
  }

  to {
    transform: translateY(-20%);
    height: calc(100% - 0px);
    width: calc(100% - 0px);
    margin: 0;
  }
}

@keyframes transition-text {
  from {
    transform: translateY(200%);
    letter-spacing: 30px;
  }

  to {
    transform: translateY(0);
    letter-spacing: 0;
  }
}
</style>
